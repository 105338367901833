import * as React from "react"
import Page from "../components/page";
import Header from "../components/header";
import {site_config} from "../config";

/*
     <div className="my-8 text-gray-700 prose mx-auto px-8">
                <div className="p-4 ">
                    <div className="font-bold uppercase text-gray-600 text-sm">Vision (Why)</div>
                    <div className="text-xl">
                        {site_config.about.vision}
                    </div>
                </div>
                <div className="p-4">
                    <div className='font-bold uppercase text-gray-600 text-sm'>Mission (How)</div>
                    <div className="text-xl">
                        {site_config.about.mission}
                    </div>
                </div>
                <div className="p-4">
                    <div className='font-bold uppercase text-gray-600 text-sm'>Platform (What)</div>
                    <div className="text-xl">
                        {site_config.about.platform.map((b, i) => <div className="mb-4" key={i}>
                            {b}
                        </div>)}
                    </div>
                </div>
                <div className="p-4">
                    <div className='font-bold uppercase text-gray-600 text-sm'>We believe:</div>
                    <div className="text-xl">
                        {site_config.about.beliefs.map((b, i) => <div className="mb-4" key={i}>
                            {b}
                        </div>)}
                    </div>
                </div>
            </div>
 */
export default function AboutPage() {
    return (
        <Page menu>
            <Header title='About'/>

            <div className="bg-gray-50 py-8">
                <div className="mb-8 text-gray-700 container max-w-6xl mx-auto px-8">
                    <div className="p-4 ">
                        <div className="font-bold uppercase text-gray-600 text-sm">Community Stack</div>
                        <div className="mt-2 shadow-lg rounded-md bg-white">
                            <div className="grid grid-cols-4 p-1 gap-1">
                                <div className="grid gap-1 auto-rows-min">
                                    <div className="relative p-2 rounded-md text-center"
                                         style={{backgroundColor: "#F5AD18"}}>
                                        <div className="text-white font-semibold text-lg">Reach</div>
                                        <div className="absolute process-square" style={{backgroundColor: "#F5AD18"}}/>
                                        <div className="absolute process-arrow" style={{backgroundColor: "#F5AD18"}}/>
                                    </div>
                                    {site_config.about.capabilities.reach.map(it => <div key={it.title}
                                                                                         className="p-3 text-sm rounded-md bg-gray-50">
                                        <div className="text-gray-700 mb-1 font-semibold">
                                            {it.title}
                                        </div>
                                        <div className="leading-relaxed">
                                            {it.items.map(c => <div className="text-gray-600" key={c}>
                                                {c}
                                            </div>)}
                                        </div>
                                    </div>)}
                                </div>
                                <div className="grid auto-rows-min gap-1">
                                    <div className="relative p-2 rounded-md text-center"
                                         style={{backgroundColor: "#F6811A"}}>
                                        <div className="absolute bg-white process-box"/>
                                        <div className="text-white font-semibold text-lg">Understand</div>
                                        <div className="absolute process-square" style={{backgroundColor: "#F6811A"}}/>
                                        <div className="absolute process-arrow" style={{backgroundColor: "#F6811A"}}/>
                                    </div>
                                    {site_config.about.capabilities.understand.map(it => <div key={it.title}
                                                                                              className="p-3 text-sm rounded-md bg-gray-50">
                                        <div className="text-gray-700 mb-1 font-semibold">
                                            {it.title}
                                        </div>
                                        <div className="leading-relaxed">
                                            {it.items.map(c => <div className="text-gray-600" key={c}>
                                                {c}
                                            </div>)}
                                        </div>
                                    </div>)}
                                </div>
                                <div className="grid auto-rows-min gap-1">
                                    <div className="relative p-2 rounded-md text-center"
                                         style={{backgroundColor: "#F45718"}}>
                                        <div className="absolute bg-white process-box"/>
                                        <div className="text-white font-semibold text-lg">Organize</div>
                                        <div className="absolute process-square" style={{backgroundColor: "#F45718"}}/>
                                        <div className="absolute process-arrow" style={{backgroundColor: "#F45718"}}/>
                                    </div>
                                    {site_config.about.capabilities.organize.map(it => <div key={it.title}
                                                                                            className="p-3 text-sm rounded-md bg-gray-50">
                                        <div className="text-gray-700 mb-1 font-semibold">
                                            {it.title}
                                        </div>
                                        <div className="leading-relaxed">
                                            {it.items.map(c => <div className="text-gray-600" key={c}>
                                                {c}
                                            </div>)}
                                        </div>
                                    </div>)}
                                </div>
                                <div className="grid auto-rows-min gap-1">
                                    <div className="relative p-2 rounded-md text-center"
                                         style={{backgroundColor: "#F12710"}}>
                                        <div className="absolute bg-white process-box"/>
                                        <div className="text-white font-semibold text-lg">Engage</div>
                                    </div>
                                    {site_config.about.capabilities.engage.map(it => <div key={it.title}
                                                                                          className="p-3 text-sm rounded-md bg-gray-50">
                                        <div className="text-gray-700 mb-1 font-semibold">
                                            {it.title}
                                        </div>
                                        <div className="leading-relaxed">
                                            {it.items.map((c,k) => <div className="text-gray-600" key={k}>
                                                {c}
                                            </div>)}
                                        </div>
                                    </div>)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-gray-900 text-center">Unaty Concepts & Terms</h2>
                    <div className="mt-12">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-12 lg:grid-cols-4">
                            {site_config.about.artifacts.map((faq) => (
                                <div key={faq.name}>
                                    <dt className="text-lg leading-6 font-medium text-gray-900">{faq.name}</dt>
                                    <dd className="mt-2 text-base text-gray-500">{faq.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

        </Page>
    )
};