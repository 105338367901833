import {changelog} from "./pages/whats-new";


export const site_config = {
    appVersion: changelog[0].v,
    about: {
        mission: 'Enable leaders to build sustainable communities, bring people together, and make community life simple and fun.',
        vision: 'Help leaders around the world unlock the power of community to build a better world.',
        platform: [
            "Unaty is a toolkit for building and running modern communities.",
            "At its core, it's a free online platform for all kinds of organizations to create and customize a community hub for their members.",
            "On top of the platform, we build features for data management, internal communications, workflow automation, and more to help leaders reach, understand, engage, and organize their members.",
            "The core platform and capabilities collectively form a solid community stack for creating sustainable communities."
        ],
        beliefs: [
            "in giving tools to leaders and people across the world to build a better world.",
            "that software shouldn't dictate how you can run your community, you should be able to customize software to run your community.",
            "that communities and members should truly own their data and not have it sold to third parties.",
            "in building great software and selling it for a fair price.",
            "simplicity is still the ultimate sophistication."
        ],
        artifacts: [
            {
                name: 'User',
                description: 'Each individual signed up for Unaty is a single user which ties back to their main account email. Users have a profile they can customize.',
            },
            { name: 'Community', description: 'Digital space for your organization to manage member data, create and join groups and stay organized.' },
            {
                name: 'Member',
                description: 'A user may be a member of one or multiple communities giving them a member profile and access to content and tools.',
            },
            { name: 'Member Type', description: 'The type or level of membership a member has attained in the organization, e.g. Coach/Player/Parent or Student/Teacher/Alum. Members can only have one member type at once.' },
            { name: 'Admin', description: 'Admins are appointed by the community owner and have special privilages to manage member data and community content.' },
            { name: 'Owner', description: 'One person who is legally responsible for the organization itself retains the position of owner.' },
            { name: 'Group', description: 'Anyone can create an open or closed groups. Anyone can join open groups, closed groups are invite only. Add members, roles, filters, or member types to groups.' },
            { name: 'Moderator', description: 'Groups can have moderators that are either members or roles who can manage the group settings, and have special access to sending group emails.' },
            { name: 'Team', description: 'Special type of group that can only contain roles tailored toward official groups and committees inside the organization.' },
            { name: 'Role', description: 'Positions like president, secretary, and treasurer that are assigned to members at various times.' },
            { name: 'File', description: 'Upload any kind of file to your community within a folder. Files are searchable by name and can be pinned to groups, the homepage, and roles.' },
            { name: 'Folder', description: 'Create a nested folder/filesystem for your community and add granular access for members, groups, filters, member types, and roles so the right people always have access.' },
            { name: 'Basic Profile', description: 'Each user and member have a basic set of fields about them and their contact information they can enter.' },
            { name: 'Custom Profile', description: 'Admins can add custom fields to collect community specific data. Fields can be marked as important to encourage members to keep these up-to-date.' },
            { name: 'Filter', description: 'Always-up-to-date single or compound filters on member data attributes like address, interests, and member type - these can be used for folders and groups.' },
            { name: 'Email Lists', description: 'Create real email addresses attached to groups and teams with members always synced. Control who can send and reply. Attachments supported and auto-saved to the filesystem.' },
            { name: 'Secondary Email', description: 'Verify multiple emails you also would like to send emails to your user and member for use across communities.' },
            { name: 'Policies', description: 'Unaty enables your organization to manage your data securely and create member-facing policies they must agree to to use the tool.' },
            { name: 'Interests', description: 'Choose from Volunteering, Reading, and 78 other interests as a member, then discover others with mutual interests.' },
            { name: 'Custom Field Types', description: 'Text, Multi-line text, Number, Date, Member, Mentor/Mentee, Group, Phone, Email, Select, Multi-select, Address, Taxonomy Select, Taxonomy Multi-select.' },
        ],
        capabilities: {
            reach: [
                {
                    title: "Community Portal",
                    items: [
                        "Custom Branding",
                        "Member Profiles",
                        "Onboarding",
                        "Birthdays"
                    ]
                },
                {
                    title: "Data Quality",
                    items: [
                        "Email Verification",
                        "Address Autocomplete",
                        "Quick Profile Data Checks",
                        "Automated Warnings"
                    ]
                },
                {
                    title: "Compliance",
                    items: [
                        "Privacy Policies",
                        "Community Guidelines",
                        "GDPR & CCPA Readiness"
                    ]
                }
            ],
            understand: [
                {
                    title: "Data Architecture",
                    items: [
                        "Custom Fields",
                        "Member Types",
                        "Member Filters",
                        "Education & Career",
                        "Data Taxonomies",
                        "Interests & Skills"
                    ]
                },
                {
                    title: "Analytics",
                    items: [
                        "Profile History",
                        "Member Activity",
                        "Engagement Scores"
                    ]
                }
            ],
            engage: [
                {
                    title: "Messaging",
                    items: [
                        "Composer",
                        "Attachments",
                        "Announcements",
                        "Scheduling"
                    ]
                },
                {
                    title: "Forum",
                    items: [
                        "Inbox",
                        "Replies",
                        "Images & Video",
                        "Polls & Audio",
                        "Content Embeds"
                    ]
                }
            ],
            organize: [
                {
                    title: "Structure",
                    items: [
                        "Smart Views",
                        "Advanced Directory",
                        "Roles & Teams",
                        "Pinboard",
                        "Access Management"
                    ]
                },
                {
                    title: "Groups",
                    items: [
                        "Mailing Lists",
                        "Entity-based",
                        "Moderators",
                        "Power-ups"
                    ]
                },
                {
                    title: "Content",
                    items: [
                        "Events",
                        "Shared Calendars",
                        "Files & Folders"
                    ]
                }
            ]
        }
    }
};